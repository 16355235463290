module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#34d399","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/plugins/remark-rewrite-images","name":"remark-rewrite-images","id":"12c59a79-c09e-55a7-8647-716f54ee6c0b","version":"4bb04515b4fcd461d699316db2fcaffb","modulePath":"/opt/build/repo/plugins/remark-rewrite-images/index.js","pluginOptions":{"plugins":[],"instanceName":"i18n","rewriteTo":"content"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/plugins/remark-extract-featured-image","name":"remark-extract-featured-image","id":"bcc63ef9-01c8-5da5-9834-c32ffdb83910","version":"ab12b56435df77ca752d36f10be1af1e","modulePath":"/opt/build/repo/plugins/remark-extract-featured-image/index.js","pluginOptions":{"plugins":[],"frontmatterKey":"linkImage","field":"featuredImage"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.24.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"301e2c90-05b1-5c6d-be70-46892493d762","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":780,"showCaptions":true,"backgroundColor":"white","linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":780,"showCaptions":true,"backgroundColor":"white","linkImagesToOriginal":false},
    },{
      plugin: require('../plugins/translations-plugin/gatsby-browser.js'),
      options: {"plugins":[],"locales":{"en":{"name":"English","enabled":true,"dayJsImport":"en"},"zh":{"name":"中文","enabled":true,"dayJsImport":"zh"},"ar":{"name":"اَلْعَرَبِيَّةُ","enabled":true,"dayJsImport":"ar","flag":"eg","rtl":true},"de":{"name":"Deutsch","enabled":true,"dayJsImport":"de"},"es":{"name":"Español","enabled":true,"dayJsImport":"es"},"el":{"name":"Ελληνικά","enabled":true,"dayJsImport":"el","flag":"gr"},"fr":{"name":"Français","enabled":true,"dayJsImport":"fr"},"he":{"name":"עִבְרִית","enabled":true,"dayJsImport":"he","flag":"il","rtl":true},"hi":{"name":"हिन्दी","enabled":true,"dayJsImport":"hi","flag":"in"},"hr":{"name":"Hrvatski","enabled":true,"dayJsImport":"hr"},"it":{"name":"Italiano","enabled":true,"dayJsImport":"it"},"ja":{"name":"日本語","enabled":true,"dayJsImport":"ja"},"ko":{"name":"한국어","enabled":true,"dayJsImport":"ko"},"ms":{"name":"Malay","enabled":true,"dayJsImport":"md","flag":"my"},"nl":{"name":"Nederlands","enabled":true,"dayJsImport":"nl"},"pt":{"name":"Português","enabled":true,"dayJsImport":"pt"},"ru":{"name":"русский","enabled":true,"dayJsImport":"ru"},"th":{"name":"ภาษาไทย","enabled":true,"dayJsImport":"th"},"tr":{"name":"Türk","enabled":true,"dayJsImport":"tr"},"vi":{"name":"Tiếng Việt","enabled":true,"dayJsImport":"vi"},"zh-yue":{"name":"粵語","enabled":true,"flag":"hk","dayJsImport":"zh-hk"}},"defaultLocale":"en","contentType":"content","i18nType":"i18n","templatesDir":"/opt/build/repo/src/templates/","collectionKey":"collection","noFallbackDirs":["blog"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
