exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-services-apps-js": () => import("./../../../src/templates/services/apps.js" /* webpackChunkName: "component---src-templates-services-apps-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-videos-js": () => import("./../../../src/templates/videos.js" /* webpackChunkName: "component---src-templates-videos-js" */)
}

